<template>
    <div>
        <loading
        :active.sync="isLoading"
        background-color="#332abb"
        :opacity="0.99"
        color="#fff"
        :is-full-page="fullPage"
        ></loading>

        <ModalBagisYap ref="modalBagisYap"></ModalBagisYap>
        <ModalKampanyaOlustur ref="modalKampanyaOlustur"></ModalKampanyaOlustur>
        <ModalHediyeKarti ref="modalHediyeKarti"></ModalHediyeKarti>
        <modalGallery ref="modalGallery"></modalGallery>
        <MainBanner
       
        Title="Ulaşılan Okullar"
        ButtonText="Bağış Yap"
        OverlapImage="/svg/banner-bilim-seferberligi.svg"
        TextPosition="between"
        Height="middle"
        />
         <div class="main-continer">
             <section class=" container">
                 <table class="schooltable">
                     <thead>
                         <tr>
                             <td class="col-md-4"><b>İl</b></td>
                             <td class="col-md-4"><b>İlçe</b></td>
                             <td class="col-md-4"><b>Okul Adı</b></td>
                         </tr>
                     </thead>
                     <tbody>
                         <tr v-for="(k , i) in schools"  :key="i">
                             <td class="col-md-4">{{k.province}}</td>
                             <td class="col-md-4">{{k.district}}</td>
                             <td class="col-md-4">{{k.school_name}}</td>
                         </tr>
                     </tbody>
                 </table>
             </section>
        
            
            <div class="spacer clearfix"></div>
         </div>
        <FooterLanding />
    </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
var VueScrollTo = require("vue-scrollto");
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import {
  Swiper,
  Scrollbar,
  EffectCoverflow,
  SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Mousewheel,
  Autoplay,
  EffectFade,
]);
import "swiper/swiper-bundle.css";

import ButtonRounded from "@/components/ButtonRounded";
import MainBanner from "@/components/MainBanner";
import FooterLanding from "@/components/FooterLanding";
import ModalBagisYap from "@/components/ModalBagisYap";
import ModalKampanyaOlustur from "@/components/ModalKampanyaOlustur";
import ModalHediyeKarti from "@/components/ModalHediyeKarti";
import BagisSec from "@/components/BagisSec";
import FloatingCard from "@/components/FloatingCard";
import ToplananBagisInfographic from "@/components/ToplananBagisInfographic";
import MultipleCarousel from "@/components/MultipleCarousel";
import ModalGallery from "@/components/ModalGallery";
export default {
    name: "Okullar",
    components: {
        ButtonRounded,
        MainBanner,
        FooterLanding,
        ModalBagisYap,
        Loading,
        BagisSec,
        FloatingCard,
        ToplananBagisInfographic,
        ModalKampanyaOlustur,
        ModalHediyeKarti,
        MultipleCarousel,
        ModalGallery,
    },
    data(){
        return{
            isLoading: false,
            fullPage: true,
            schools: [],
        };
    },
    mounted(){
        this.getInfographic();
    },
    methods:{
        getInfographic() {
        this.$api.getSchoolList().then((response) => {
           console.log(response);
           this.schools = response;
        });
        },
    },

}
</script>
<style lang="scss">
    .single-download{
        padding: 1rem 0px;
        border-top: 1px solid #efefef;
        p{
            font-size: 1.2rem;
            b{
                font-weight: 600;
            }
        }
        span{
            background: var(--purpleDark);
            width: 20px;
            height: 20px;
        }
    }
    .schooltable{
        tr{
                border-radius: 10px;
                padding: 5px 0px;
                border-top: 1px solid #efefef;
                height: 35px;
        }
        @media (max-width:567px) {
                max-width: 375px;
                overflow-x: scroll;
                width: 375px;
                display: block;
        }
    }
</style>